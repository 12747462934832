export default {
  getCookie: function (name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  },
  getQueryVariable: function (location, variable) {
    var query = location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return ""
  },
  registerEvent: function(type) {
    if(typeof(window.dataLayer) == 'object') {
      window.dataLayer.push({'event': type});
    } else {
      console.log("No dataLayer. Debug?", type)
    }
  },
  eventRegistrar: function(type) {
    return function() {
      if(typeof(window.dataLayer) == 'object') {
        window.dataLayer.push({'event': type});
      } else {
        console.log("No dataLayer. Debug?", type)
      }
    }
  }
}
