import React from "react"
import { FaGithub, FaTwitterSquare, FaMedium, FaDiscord } from "react-icons/fa"

export default [
  {
    icon: <FaGithub />,
    url: "https://github.com/openrelayxyz/ethercattle-deployment",
  },
  {
    icon: <FaTwitterSquare />,
    url: "https://twitter.com/R_I_V_E_T",
  },
  {
    icon: <FaMedium />,
    url: "https://medium.com/rivet-magazine",
  },
  {
    icon: <FaDiscord />,
    url: "https://discord.gg/HgQUGX2UVt",
  },
]
