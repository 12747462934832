import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

class LoginButton extends React.Component {
  constructor () {
    super();
    this.state = {};
  }
  componentDidMount() {
    fetch("/api/account").then((result) => {
      this.setState({"authenticated": result.ok})
    })
  }
  render() {
    if (this.state.authenticated) {
      return (
        <li>
          <a href="/dashboard/">
            Dashboard
          </a>
        </li>
      )
    } else {
      return (<li>
        <AniLink fade to="/login/">
          Login
        </AniLink>
      </li>)

    }
  }
}

export default LoginButton
