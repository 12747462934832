/* eslint-disable */
import React, { useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../css/navbar.module.css"
import { FaAlignRight } from "react-icons/fa"
import links from "../constants/links"
import socialIcons from "../constants/social-icons"
import logo from "../images/logo.svg"
import LoginButton from './Home/LoginButton';

const Navbar = () => {
  const [isOpen, setNav] = useState()
  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  return (
    <nav className={styles.navbar}>
      <div className={styles.navCenter}>
        <div className={styles.navHeader}>
          <AniLink paintDrip to="/" hex="#070707"><span className={styles.rivetText}>RIVET</span><img src={logo} alt="rivet logo" className={styles.logoSVG} /></AniLink>
          <button type="button" className={styles.logoBtn} onClick={toggleNav}>
            <FaAlignRight className={styles.logoIcon} />
          </button>
        </div>
        <ul
          className={
            isOpen
              ? `${styles.navLinks} ${styles.showNav}`
              : `${styles.navLinks}`
          }
        >
          {links.map((item, index) => {
            return (
              <li key={index}>
                <AniLink fade hex='#090909' to={item.path} duration={1}>
                  {item.text}
                </AniLink>
              </li>
            )
          })}
          <li>
            <a href="/docs/" className={"navLinks"}>
              docs
            </a>
          </li>
          <LoginButton />
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
